import React from "react";
import {Text} from "../ui/Text";
import Col from "../ui/Col";
import mq from "../ui/media-queries";
import Row from "../ui/Row";
import xcolors from "../../lib/xcolors";
import Icon from "../Icon";
import Avatar from "../Avatar";

const Quote = ({dark, children, authorName, authorInfo, companyName, isRight, img, isBig}) => (
  <Col
    sp={4}
    bg="white"
    px={5}
    py={4}
    rounded="lg"
    border="purple300"
    relative
    css={{
      maxWidth: "40rem",
      alignSelf: isRight ? "flex-end" : "flex-start",
      [mq.sm]: {paddingLeft: "1rem", paddingRight: "1rem"},
    }}
  >
    <Col absolute css={{top: "-2.2rem", [isRight ? "right" : "left"]: "-2.5rem"}}>
      <Icon.Quote css={{color: xcolors.purple200, fontSize: "5rem"}} />
    </Col>
    <Text
      size={isBig ? 5 : 4}
      color={dark ? "white" : "gray800"}
      lineHeight="wide"
      at={{[mq.sm]: {size: 3}}}
    >
      {children}
    </Text>
    <Row sp={2} align="center">
      {img && <Avatar name={img} />}
      <Row sp={2} align="baseline" breakAt={mq.md}>
        <Row sp={0} align="baseline" breakAt={mq.sm}>
          <Text color="gray700" preset="bold">
            {authorName}
          </Text>
          {authorInfo && (
            <Text color="gray500" size={2}>
              – {authorInfo}
            </Text>
          )}
        </Row>
        {companyName && (
          <Text size={2} color="gray700">
            {companyName}
          </Text>
        )}
      </Row>
    </Row>
  </Col>
);

export default Quote;
