import React from "react";
import Helmet from "react-helmet";
import DefaultLayout from "../components/DefaultLayout";
import Row from "../components/ui/Row";
import GhostButton from "../components/ui/GhostButton";
import Col from "../components/ui/Col";
import Hero, {HeroForm} from "../components/bricks/Hero";
import SingleFeature from "../components/bricks/LegacySingleFeature";
import MultiFeature from "../components/bricks/MultiFeature";
import Heading from "../components/bricks/Heading";
import Paragraphs from "../components/bricks/Paragraphs";
import Quote from "../components/bricks/Quote";

const Heros = () => {
  const model = {
    title: "Playful Project Management for Game Development",
    subline:
      "Codecks is tailored for game development. This sentence could obviously be better! It now comes with a Discord integration built-in.",
  };
  return (
    <Col bg="gray700" sp={6} py={7} fillParent>
      <Hero
        img="next/decks.png"
        title={model.title}
        subline={model.subline}
        form={<HeroForm placeholder="Your email" buttonLabel="Get Started" />}
      />

      <Hero
        size="sm"
        title="Feature overview"
        subline="See how Codecks helps you to stay on top of things."
        bottomImg="barbarian.svg"
      />

      <Hero
        title={model.title}
        subline={model.subline}
        form={<HeroForm placeholder="Your email" buttonLabel="Get Started" />}
      />

      <Hero
        title={model.title}
        form={<HeroForm placeholder="Your email" buttonLabel="Get Started" />}
      />

      <Hero img="next/decks.png" title={model.title} subline={model.subline} />

      <Hero title={model.title} subline={model.subline} />
    </Col>
  );
};
const TextWithImage = () => {
  const model = [
    {
      lead: "Feature",
      title: "Get things done fast & easy",
      description:
        "Simple and effective workflow ensures you always know what state your work is at.",
      button: {label: "Learn More", url: "/"},
      img: "cards.jpg",
    },
    {
      lead: "Feature",
      title: "Keep everyone in the loop by design",
      description:
        "Threaded conversations with decisive opt-in/out actions ensure that issues are always closed out.",
      button: {label: "Learn More", url: "/"},
      img: "next/decks.png",
    },
    {
      lead: "Feature",
      title: "Know when something important happens",
      description:
        "Every event relevant for you will queue up in your notifications. You’ll never miss out.",
      button: {label: "Learn More", url: "/"},
      img: "milestones.jpg",
    },
  ];

  const simpleModel = model.map(({button, lead, ...rest}) => rest);

  return (
    <Col bg="gray700" sp={6} py={7} fillParent>
      <Col>
        <SingleFeature
          lead={model[0].lead}
          title={model[0].title}
          description={model[0].description}
          button={model[0].button}
          img={model[0].img}
        />
        <SingleFeature
          lead={model[1].lead}
          title={model[1].title}
          description={model[1].description}
          button={model[1].button}
          img={model[1].img}
          imgRight
          imgAlt="Eight Decks"
        />
        <SingleFeature
          lead={model[2].lead}
          title={model[2].title}
          description={model[2].description}
          button={model[2].button}
          img={model[2].img}
        />
      </Col>

      <Col>
        <SingleFeature
          lead={model[0].lead}
          title={model[0].title}
          description={model[0].description}
          button={model[0].button}
          img={model[0].img}
          dark
        />
        <SingleFeature
          lead={model[1].lead}
          title={model[1].title}
          description={model[1].description}
          button={model[1].button}
          img={model[1].img}
          imgRight
          dark
        />
        <SingleFeature
          lead={model[2].lead}
          title={model[2].title}
          description={model[2].description}
          button={model[2].button}
          img={model[2].img}
          dark
        />
      </Col>

      <MultiFeature features={model.slice(0, 2)} />
      <MultiFeature dark features={model.slice(0, 2)} />

      <MultiFeature features={model} />
      <MultiFeature dark features={model} />

      <MultiFeature features={simpleModel} />
      <MultiFeature dark features={simpleModel} />
    </Col>
  );
};

const Headings = () => (
  <Col bg="gray700" sp={6} py={7} fillParent>
    <Heading>Default heading</Heading>
    <Heading dark>Default heading on dark</Heading>

    <Heading size="xl">XL heading for some weight</Heading>
    <Heading dark size="xl">
      XL heading for some weight on dark
    </Heading>

    <Heading size="sm">Small heading for some weight</Heading>
    <Heading dark size="sm">
      Small heading for some weight on dark
    </Heading>
  </Col>
);

const text = (
  <React.Fragment>
    <p>Dear Game Developer,</p>
    <p>
      project management tools are notoriously unsatisfying to use. We found all the tools that we
      tried to be either too complex or too simplistic.{" "}
      <b>
        We believe that game developers deserve their own production tool that is just right for
        them.
      </b>
    </p>
    <p>
      Codecks is a project by the indie game company{" "}
      <b>
        <a href="https://maschinen-mensch.com">Maschinen-Mensch</a>
      </b>{" "}
      and some extraordinarily skilled web developers. We've been working on it since 2014 and
      believe that we've created something special:
    </p>
    <p>
      A tool that aims at the right amount of complexity and is able to excite not only the producer
      on your team, but also the artists and everybody else. Something with{" "}
      <b>joyful interaction</b> at its core instead of an afterthought.
    </p>
    <p>
      <b>Codecks is our love letter to the people who make games.</b>
    </p>
    <p>
      <em>- The Codecks Team</em>
    </p>
  </React.Fragment>
);

const Texts = () => (
  <Col bg="gray700" sp={6} py={7} fillParent>
    <Paragraphs>{text}</Paragraphs>
    <Paragraphs thin>{text}</Paragraphs>

    <Paragraphs dark>{text}</Paragraphs>
    <Paragraphs dark thin>
      {text}
    </Paragraphs>

    <Quote authorName="Milan Pingel" companyName="Massive Miniteam">
      After trying pretty much all of the competitors, we settled on Codecks as the one we're most
      comfortable with for our varied team of ~10 artists, designers, developers and producers. The
      adaptive design and visual card ordering is easy to get into and perfect for keeping track of
      multiple projects at once. Also a big bonus: the direct feedback loop for bugs and feature
      requests with the developers - our wishes are being heard and responded to.
    </Quote>
    <Quote dark authorName="Sam Luckhardt" companyName="Tonkotsu Games">
      If you've ever felt like falling asleep while using Jira, Trello or writing the 451st user
      story, this is the PJM tool for you. Elegant, responsive &amp; more fun than shuffling sleeved
      cards.
    </Quote>
    <Quote thin authorName="Paul Lawitzki" companyName="Chasing Carrots">
      We've tried a lot of agile PM solutions in the past. With Codecks it seems like we've found
      our final best fit. It's lean and easy to get into. Yet it's powerful and satisfies all our
      needs regarding agile project management.
    </Quote>
  </Col>
);

const Example = () => (
  <Col>
    <Hero
      img="next/decks.png"
      title="Say hi to Decky"
      subline="The world's premier source for developer happiness"
      form={<HeroForm placeholder="Your email" buttonLabel="Get Started" />}
    />
    <MultiFeature
      features={[
        {
          img: "next/decks.png",
          title: "When you and your fans become one",
          description: "Channel the chaotic influx of bug reports and proposals into a system",
        },
        {
          img: "milestones.jpg",
          title: "Your fans are belong to us",
          description:
            "Manage various conversations easily without having to constantly look up user names",
        },
        {
          img: "cards.jpg",
          title: "Your server on steroids",
          description:
            "Empower and kick-start your passive community to become part of the extended dev team",
        },
      ]}
    />
    <Heading dark>Features you gonna love</Heading>
    <SingleFeature
      lead="Feature"
      title="When you and your fans become one"
      description="Channel the chaotic influx of bug reports and proposals into a system"
      img="next/decks.png"
      dark
    />
    <SingleFeature
      lead="Feature"
      title="Your fans are belong to us"
      description="Manage various conversations easily without having to constantly look up user names"
      img="milestones.jpg"
      imgRight
      dark
    />
    <SingleFeature
      lead="Feature"
      title="Your server on steroids"
      description="Empower and kick-start your passive community to become part of the extended dev team"
      img="cards.jpg"
      dark
    />
  </Col>
);

const types = [
  {
    label: "Hero",
    comp: Heros,
  },
  {
    label: "Heading",
    comp: Headings,
  },
  {
    label: "Text",
    comp: Texts,
  },
  {
    label: "Text with image",
    comp: TextWithImage,
  },
  {
    label: "Example Page",
    comp: Example,
  },
];

const Navi = ({activeBrickIndex, setActiveBrickIndex}) => (
  <Row justify="center" sp={3} px={4} py={3} bg="gray200">
    {types.map((t, i) => (
      <GhostButton
        key={i}
        size="sm"
        active={activeBrickIndex === i}
        onClick={() => setActiveBrickIndex(i)}
      >
        {t.label}
      </GhostButton>
    ))}
  </Row>
);

const Bricks = ({location}) => {
  const [activeBrickIndex, setActiveBrickIndex] = React.useState(0);
  const activeBrick = types[activeBrickIndex];
  return (
    <DefaultLayout title="Bricks" footer={null} location={location}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Navi activeBrickIndex={activeBrickIndex} setActiveBrickIndex={setActiveBrickIndex} />
      {activeBrick && <activeBrick.comp />}
    </DefaultLayout>
  );
};

export default Bricks;
