import React from "react";
import Strip from "../ui/Strip";
import Document from "../Document";
import xcolors from "../../lib/xcolors";
import css from "@emotion/css";

const styles = {
  darkFont: css({color: xcolors.gray800}),
  lightFont: css({color: xcolors.white}),
};

const Paragraphs = ({dark, thin, children, noPadding}) => (
  <Strip
    size={thin ? "xs" : "sm"}
    pt={noPadding ? 0 : 7}
    pb={noPadding ? 0 : 8}
    bg={dark ? "gray900" : "white"}
  >
    <Document css={dark ? styles.lightFont : styles.darkFont}>{children}</Document>
  </Strip>
);

export default Paragraphs;
