import React from "react";
import Col from "../ui/Col";
import css from "@emotion/css";
import mq from "../ui/media-queries";
import {Text} from "../ui/Text";
import Row from "../ui/Row";
import Strip from "../ui/Strip";
import {classes} from "../ui/classes";
import GhostButton from "../ui/GhostButton";
import useMedia from "../../lib/useMedia";
import FeatureImage from "./FeatureImage";
import xcolors from "../../lib/xcolors";

const styles = {
  imgContainer: css({
    flex: "1",
    width: "50%",
    [mq.md]: {
      width: "100%",
      maxWidth: "40rem",
    },
  }),
  img: css({
    width: "100%",
  }),
  outer: css({
    flex: "1",
    width: "50%",
    [mq.md]: {
      width: "100%",
      alignItems: "center",
    },
  }),
};

const LegacySingleFeature = ({
  dark,
  lead,
  title,
  description,
  children,
  img,
  imgAlt,
  button,
  imgRight,
}) => {
  const isSmall = useMedia(mq.md);
  return (
    <Strip size="md" py={7} bg={dark ? "gray900" : "white"}>
      <Row sp={7} align="center" breakAt={mq.md}>
        {(isSmall || !imgRight) && (
          <Row css={styles.imgContainer} justify="center">
            <FeatureImage css={[classes.boxShadow[2], styles.img]} img={img} alt={imgAlt} />
          </Row>
        )}
        <Col css={styles.outer}>
          <Col sp={3} align="start" css={{maxWidth: "25rem", [mq.md]: {maxWidth: "100%"}}}>
            {lead && (
              <Col pb={2}>
                <Text preset="label" color="active">
                  {lead}
                </Text>
              </Col>
            )}
            <Text
              preset="bold"
              size={6}
              as="h3"
              color={dark ? "white" : "gray800"}
              at={{[mq.sm]: {size: 5}}}
            >
              {title}
            </Text>
            {(children || description) && (
              <Text
                color={dark ? "gray300" : "gray600"}
                size={4}
                lineHeight="wide"
                css={{
                  width: "100%",
                  "& > a": {
                    fontWeight: "bold",
                    transitionProperty: "color",
                    color: dark ? xcolors.purple300 : xcolors.purple600,
                    ":hover": {
                      color: dark ? xcolors.purple200 : xcolors.purple500,
                    },
                  },
                }}
              >
                {children || description}
              </Text>
            )}
            {button && (
              <GhostButton
                data-feature-button
                color={dark ? "white" : "default"}
                to={button.url}
                href={button.href}
              >
                {button.label}
              </GhostButton>
            )}
          </Col>
        </Col>
        {!isSmall && imgRight && (
          <Row css={styles.imgContainer} justify="center">
            <FeatureImage css={[classes.boxShadow[2], styles.img]} img={img} alt={imgAlt} />
          </Row>
        )}
      </Row>
    </Strip>
  );
};

export default LegacySingleFeature;
