import React from "react";

const matchMedia = typeof window !== "undefined" && window.matchMedia ? window.matchMedia : null;

const useMedia = query => {
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const matcher = React.useMemo(
    () =>
      isFirstRender ? false : matchMedia ? matchMedia(query.replace(/^@media\s+/, "")) : false,
    [query, isFirstRender]
  );
  React.useLayoutEffect(() => {
    setIsFirstRender(false);
  }, []);
  const [matches, setMatches] = React.useState(() => (matcher ? matcher.matches : false));

  React.useEffect(() => {
    if (matcher) {
      const handler = () => setMatches(matcher.matches);
      matcher.addListener(handler);
      if (!isFirstRender) handler();
      return () => matcher.removeListener(handler);
    }
  }, [matcher, isFirstRender]);

  return matches;
};

export default useMedia;
